<!--
 * @Description: 
 * @Author: luqisheng
 * @Date: 2023-08-22 10:58:54
 * @LastEditTime: 2024-07-24 09:39:23
 * @LastEditors: luqisheng
-->
<template>
    <div class="home-mobile">
        <div class="header">
            <div class="header-container">
                <div class="l-box">
                    <div class="logo">
                        <img :src="logoImg" alt="" />
                    </div>
                </div>
                <div class="r-box">
                    <div class="experience-now" @click="onExperience">立即体验</div>
                </div>
            </div>
        </div>
        <div class="bg-box">
            <img src="@/assets/mobile/bg.png" width="100%" />
            <div class="bg-content">
                <img src="@/assets/mobile/bg-title.png" class="bg-title" />
                <div class="bg-desc">
                    <div class="bg-desc-container">
                        <div class="bg-desc-text">缔 造 有 灵 魂</div>
                    </div>
                    <div class="bg-desc-container">
                        <div class="bg-desc-text">有 温 度 的 夜 店 智 能 管 理 系 统</div>
                    </div>
                </div>
                <div class="bg-experience-btn" @click="onExperience">立即体验</div>
            </div>
        </div>
        <div class="home-content">
            <div class="function-introduction">
                <div class="function-title">产品核心功能</div>
                <div class="function-list">
                    <div class="function-list__item">
                        <img class="icon" src="~@/assets/mobile/order.png" alt="" />
                        <div class="title">点单</div>
                        <div class="line"></div>
                        <div class="desc">顾客扫码点单、员工点单、银台点单、退单 退品、多方式支付、后结挂账等</div>
                    </div>
                    <div class="function-list__item">
                        <img class="icon" src="~@/assets/mobile/access-to-alcohol.png" alt="" />
                        <div class="title">存取酒</div>
                        <div class="line"></div>
                        <div class="desc">存酒、顾客自助取酒、员工取酒、过期续存、过期充公、过期作废、存酒统计等</div>
                    </div>
                    <div class="function-list__item">
                        <img class="icon" src="~@/assets/mobile/art-performance.png" alt="" />
                        <div class="title">演艺</div>
                        <div class="line"></div>
                        <div class="desc">点歌、点舞、艺人管理、演艺商品管理、演艺业绩提成、演艺报表等</div>
                    </div>
                    <div class="function-list__item">
                        <img class="icon" src="~@/assets/mobile/marketing.png" alt="" />
                        <div class="title">营销</div>
                        <div class="line"></div>
                        <div class="desc">优惠券、一元购、幸运抽奖、营销广告、会员充值分销、相关统计等</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <div class="footer-container">
                <div class="introduce-container">
                    <div class="title">关于我们</div>
                    <div class="desc">
                        小娱儿凭借十多年夜场经验的积累，专为餐吧、清吧、酒馆和Live
                        House打造了一款全新的SAAS管理系统。该系统集管理、运营、推广和拓客等功能于一体，成为一个综合服务平台，覆盖经营管理的各个环节。它为酒馆提供一站式解决方案，旨在帮助商户实现持续盈利和快速增长。
                    </div>
                </div>
                <div class="company-info">
                    <div class="title">{{ companyName }}</div>
                    <div class="desc-list">
                        <div class="desc-list__item">
                            <img src="@/assets/mobile/phone.png" class="icon" />
                            <div class="line"></div>
                            <img src="@/assets/mobile/wx.png" class="icon" />
                            <div class="text">13246898981</div>
                        </div>
                        <div class="desc-list__item">
                            <img src="@/assets/mobile/address.png" class="icon" />
                            <div class="text">广州·天河区·高普路138号·京华信息东座·4楼</div>
                        </div>
                    </div>
                    <div class="code-list">
                        <div class="code-item">
                            <img :src="experienceImg" class="icon" />
                            <div class="text">欢迎扫码体验</div>
                        </div>
                        <div class="code-item">
                            <img src="@/assets/home/customer-service.png" class="icon" />
                            <div class="text">企业客服</div>
                        </div>
                    </div>
                </div>
                <p class="ip" data-v-023d1e28="">
                    <a
                        href="https://beian.miit.gov.cn/"
                        target="_blank"
                        style="color: rgba(255, 255, 255, 0.3)"
                        data-v-023d1e28=""
                        class="text"
                    >
                        {{ footerIcpText }}
                    </a>
                </p>
            </div>
        </div>
    </div>
    <Modal v-model="experienceModal" footer-hide class="experience-modal-mobile">
        <div class="experience-modal-content">
            <div class="title">扫码体验</div>
            <img class="experience-bg" src="@/assets/mobile/experience-bg.png" alt="" />
            <img class="experience-code" :src="experienceImg" alt="" />
        </div>
    </Modal>
</template>
<script>
import { defineComponent } from 'vue';
import { mapState, mapActions } from 'vuex';
import mixins from '@/mixins/index.js';
export default defineComponent({
    mixins: [mixins],
    name: 'home-mobile',
    components: {},
    computed: {
        ...mapState([]),
        experienceImg() {
            let url = '';
            if (this.isXyeEnv) url = require('@/assets/home/xye-experience-code.jpg');
            if (this.isXyePlusEnv) url = require('@/assets/home/xye-plus-experience-code.png');
            return url;
        },
        logoImg() {
            let url = '';
            if (this.isXyeEnv) url = require('@/assets/mobile/xye-logo.png');
            if (this.isXyePlusEnv) url = require('@/assets/mobile/xye-plus-logo.png');
            return url;
        },
    },
    data() {
        return {
            experienceModal: false,
        };
    },
    created() {},
    methods: {
        ...mapActions([]),
        onExperience() {
            // window.open(
            //     'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzA5NjY1NzE1Mw==&scene=110#wechat_redirect'
            // );
            this.experienceModal = true;
        },
    },
    mounted() {},
});
</script>
<style lang="less" scoped>
@import './index';
</style>
<style lang="less">
@media (max-width: 576px) {
    .experience-modal-mobile.ivu-modal {
        width: 80vw !important;
        margin: 10px auto;
    }
}
</style>
