/*
 * @Description:
 * @Author: luqisheng
 * @Date: 2024-03-25 13:59:44
 * @LastEditTime: 2024-04-10 16:22:53
 * @LastEditors: luqisheng
 */
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "view-ui-plus/dist/styles/viewuiplus.css";
// 多语言
// import i18n from "@/i18n";
// ViewUIPlus
import ViewUIPlus from "view-ui-plus";
import { getTimestamp } from "@/utils/request/sysTime";
import MetaInfo from 'vue-meta-info'
const app = createApp(App).use(ViewUIPlus).use(store).use(router).use(MetaInfo)
const init = async () => {
  await getTimestamp();
  app.mount("#app");
};
init();
