<!--
 * @Description: 
 * @Author: luqisheng
 * @Date: 2023-08-22 10:58:54
 * @LastEditTime: 2024-07-24 09:39:23
 * @LastEditors: luqisheng
-->
<template>
    <Mobile v-if="isMobile || showMobileView"></Mobile>
    <div v-if="isPc" class="home">
        <div class="header">
            <div class="header-container">
                <div class="l-box">
                    <div class="logo">
                        <img :src="logoImg" alt="" />
                    </div>
                </div>
                <div class="r-box">
                    <div class="nav">
                        <div class="nav-item" @click="scrollToElement('#loginBox')">商户登录</div>
                    </div>
                    <div class="experience-now" @click="experienceModal = true">立即体验</div>
                </div>
            </div>
        </div>
        <div class="bg-box">
            <img src="@/assets/home/bg.png" width="100%" ref="bgRef" />
            <div class="login-box" id="loginBox">
                <div class="login_tabs">
                    <div
                        :class="loginMethod == LoginType.ZKT ? 'is_active' : ''"
                        @click="changeLoginTab(LoginType.ZKT)"
                    >
                        咨客台
                    </div>
                    <div :class="loginMethod == LoginType.P ? 'is_active' : ''" @click="changeLoginTab(LoginType.P)">
                        商户后台
                    </div>
                </div>
                <div class="login-content">
                    <div class="wxlogin-code" ref="wxloginCode">
                        <!-- 「咨客台」登录 -->
                        <wxlogin
                            v-if="zktLoginWxOptions && loginMethod == LoginType.ZKT"
                            :appid="zktLoginWxOptions.appid"
                            scope="snsapi_login"
                            :redirect_uri="zktLoginWxOptions.redirect_uri"
                            :href="zktLoginWxOptions.href"
                        />
                        <!-- 「商家后台」登录 -->
                        <wxlogin
                            v-if="pLoginWxOptions && loginMethod == LoginType.P"
                            :appid="pLoginWxOptions.appid"
                            scope="snsapi_login"
                            :redirect_uri="pLoginWxOptions.redirect_uri"
                            :href="pLoginWxOptions.href"
                        />
                    </div>
                    <div class="wxlogin-desc">
                        <span class="desc">微信扫码登录</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="home-content">
            <div class="function-introduction">
                <div class="function-title">产品核心功能</div>
                <div class="function-list">
                    <div class="function-list__item">
                        <img class="icon" src="~@/assets/home/order.png" alt="" />
                        <div class="title">点单</div>
                        <div class="line"></div>
                        <div class="desc">顾客扫码点单、员工点单、银台点单、退单 退品、多方式支付、后结挂账等</div>
                    </div>
                    <div class="function-list__item">
                        <img class="icon" src="~@/assets/home/access-to-alcohol.png" alt="" />
                        <div class="title">存取酒</div>
                        <div class="line"></div>
                        <div class="desc">存酒、顾客自助取酒、员工取酒、过期续存、过期充公、过期作废、存酒统计等</div>
                    </div>
                    <div class="function-list__item">
                        <img class="icon" src="~@/assets/home/art-performance.png" alt="" />
                        <div class="title">演艺</div>
                        <div class="line"></div>
                        <div class="desc">点歌、点舞、艺人管理、演艺商品管理、演艺业绩提成、演艺报表等</div>
                    </div>
                    <div class="function-list__item">
                        <img class="icon" src="~@/assets/home/marketing.png" alt="" />
                        <div class="title">营销</div>
                        <div class="line"></div>
                        <div class="desc">优惠券、一元购、幸运抽奖、营销广告、会员充值分销、相关统计等</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer">
            <div class="footer-container">
                <div class="introduce">
                    <div class="introduce__item">
                        <div class="title">关于我们</div>
                        <div class="desc">
                            小娱儿凭借十多年夜场经验的积累，专为餐吧、清吧、酒馆和Live
                            House打造了一款全新的SAAS管理系统。该系统集管理、运营、推广和拓客等功能于一体，成为一个综合服务平台，覆盖经营管理的各个环节。它为酒馆提供一站式解决方案，旨在帮助商户实现持续盈利和快速增长。
                        </div>
                    </div>
                    <div class="introduce__item">
                        <div class="title">{{ companyName }}</div>
                        <div class="desc-list">
                            <div class="desc-list__item">
                                <div class="label">
                                    <img src="@/assets/home/phone.png" class="icon" />
                                    <span class="text">微信/手机号</span>
                                </div>
                                <div class="value">13246898981</div>
                            </div>
                            <!-- <div class="desc-list__item">
                                <div class="label">
                                    <img src="@/assets/home/email.png" class="icon" />
                                    <span class="text">电子邮件</span>
                                </div>
                                <div class="value">123456@163.com</div>
                            </div> -->
                            <div class="desc-list__item">
                                <div class="label">
                                    <img src="@/assets/home/address.png" class="icon" />
                                    <span class="text">地址</span>
                                </div>
                                <div class="value">广州·天河区·高普路138号·京华信息东座·4楼</div>
                            </div>
                        </div>
                    </div>
                    <div class="introduce__item">
                        <div class="code-list">
                            <div class="code-item">
                                <img :src="experienceImg" class="icon" />
                                <div class="text">欢迎扫码体验</div>
                            </div>
                            <div class="code-item">
                                <img src="@/assets/home/customer-service.png" class="icon" />
                                <div class="text">企业客服</div>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="ip" data-v-023d1e28="">
                    <a
                        href="https://beian.miit.gov.cn/"
                        target="_blank"
                        style="color: rgba(255, 255, 255, 0.3)"
                        data-v-023d1e28=""
                        class="text"
                    >
                        {{ footerIcpText }}
                    </a>
                </p>
            </div>
        </div>
    </div>
    <Modal width="400px" v-model="experienceModal" footer-hide>
        <div class="experience-modal-content">
            <div class="title">扫码体验</div>
            <img class="experience-bg" src="@/assets/home/experience-bg.png" alt="" />
            <img class="experience-code" :src="experienceImg" alt="" />
        </div>
    </Modal>
</template>
<script>
import { defineComponent } from 'vue';
import { LoginConfig, XyePlusLoginConfig } from '@/config';
import wxlogin from '@/components/VueWxlogin/index.vue';
import { mapState, mapActions } from 'vuex';
import { postUserWxLogin } from '@/api/sys';
import { getQueryObject } from '@/utils';
import Mobile from './components/mobile/index.vue';
import mixins from '@/mixins/index.js';

const LoginType = {
    ZKT: 'zkt',
    P: 'p',
};

const LoginTypeStoreKey = 'loginType' + process.env.VUE_APP_NAMECODE;

const LoginTokenStoreKey = 'loginToken' + process.env.VUE_APP_NAMECODE;

export default defineComponent({
    name: 'page-account-login',
    metaInfo: {
        title: '小娱儿酒馆SaaS系统',
        meta: [
            {
                name: 'keyWords',
                content: '缔 造 有 灵 魂',
            },
            {
                name: 'description',
                content: '有温度的夜店智能管理系统',
            },
        ],
    },
    mixins: [mixins],
    components: { wxlogin, Mobile },
    computed: {
        ...mapState([]),
        title() {
            return '小娱儿';
        },
        isMobile() {
            return this.screenWidth < 800;
        },
        isPc() {
            return this.screenWidth > 800;
        },
        experienceImg() {
            let url = '';
            if (this.isXyeEnv) url = require('@/assets/home/xye-experience-code.jpg');
            if (this.isXyePlusEnv) url = require('@/assets/home/xye-plus-experience-code.png');
            return url;
        },
        logoImg() {
            let url = '';
            if (this.isXyeEnv) url = require('@/assets/home/xye-logo.png');
            if (this.isXyePlusEnv) url = require('@/assets/home/xye-plus-logo.png');
            return url;
        },
        wxLoginConfig() {
            if (this.isXyeEnv) {
                return JSON.parse(JSON.stringify(LoginConfig));
            }
            if (this.isXyePlusEnv) {
                return JSON.parse(JSON.stringify(XyePlusLoginConfig));
            }
            return {};
        },
    },
    data() {
        return {
            experienceModal: false,
            screenWidth: document.body.clientWidth,
            zktLoginWxOptions: null,
            pLoginWxOptions: null,
            loginMethod: sessionStorage.getItem(LoginTypeStoreKey) || LoginType.ZKT, // 商户后台登录，咨客台登录
            showMobileView: false,
            pageQuery: {},
            LoginType,
        };
    },
    created() {},
    methods: {
        ...mapActions([]),

        /**
         * 平滑滚动到元素
         * @param {*} elementId 元素ID
         */
        scrollToElement(elementId) {
            this.$nextTick(() => {
                const element = document.getElementById(elementId.slice(1));
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            });
        },
        wxLoginCodeZkt() {
            const reader = this.wxLoginBlob();
            const that = this;
            reader.onload = function () {
                that.zktLoginWxOptions = {
                    appid: that.wxLoginConfig.LOGIN_APPID,
                    redirect_uri: that.wxLoginConfig.LOGIN_REDIRECT_URI,
                    href: this.result,
                };
            };
        },
        wxLoginCodeP() {
            const reader = this.wxLoginBlob();
            const that = this;
            reader.onload = function () {
                that.pLoginWxOptions = {
                    appid: that.wxLoginConfig.LOGIN_APPID,
                    redirect_uri: that.wxLoginConfig.LOGIN_REDIRECT_URI,
                    href: this.result,
                };
            };
        },
        wxLoginBlob() {
            const width = 200;
            var content = `.impowerBox .wrp_code {text-align: left;} .impowerBox .qrcode {width: ${width}px;margin-top:0;border:none;}.impowerBox .title {display: none;}.impowerBox .info {display: none;}.qlogin_mod {width: ${width}px; `;
            var blob = new Blob([content], {
                type: 'text/css;charset=utf-8',
            });
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            return reader;
        },

        /**
         * 切换时缓存登录类型
         * @param {*} type 登录类型
         */
        changeLoginTab(type) {
            this.loginMethod = type;
            sessionStorage.setItem(LoginTypeStoreKey, type);
        },

        /**
         * 调用登录接口并跳转到相应的后台
         * @param {*} code
         */
        openLoginApi(code) {
            const data = {
                login_way: this.wxLoginConfig.LOGIN_WAY,
                login_extra: JSON.stringify({
                    code: code,
                }),
            };
            postUserWxLogin(data).then(res => {
                if (res.code === 200) {
                    const { jwt_token, user_info, tenant } = res.data;
                    // if (!tenant) {
                    //     this.$message.error('你没有绑定任何酒吧！');
                    //     return;
                    // }
                    setTimeout(() => {
                        const zkt = this.isXyeEnv
                            ? 'https://zk.xye.wang/#/pages/zkt/index'
                            : 'https://zk.xye.cn/#/pages/zkt/index';
                        const p = this.isXyeEnv ? 'https://ht.xye.wang/#/index' : 'https://ht.xye.cn/#/index';
                        const hrefObj = {
                            [LoginType.ZKT]: zkt,
                            [LoginType.P]: p,
                        };
                        const url = hrefObj[this.loginMethod];
                        window.open(`${url}?token=${jwt_token.accessToken}`, '_self');
                    }, 300);
                }
            });
        },

        /**
         * 监听页面大小变化
         */
        onPageSizeChange() {
            const that = this;
            window.onresize = () => {
                return (() => {
                    window.screenWidth = document.body.clientWidth;
                    that.screenWidth = window.screenWidth;
                    this.showMobileView = this.screenWidth === 800;
                    // console.log(that.screenWidth, 'that.screenWidth');
                })();
            };
            setTimeout(() => {
                if (this.screenWidth === 800) {
                    this.showMobileView = true;
                }
            }, 2000);
        },
    },
    mounted() {
        console.log(this.isXyeEnv, '---isXyeEnv');
        const query = getQueryObject();
        console.log('query: ', query);
        this.pageQuery = query || {};
        const oldCode = sessionStorage.getItem(LoginTokenStoreKey);
        if (query.code && query.code !== oldCode) {
            sessionStorage.setItem(LoginTokenStoreKey, query.code);
            this.openLoginApi(query.code);
        }
        this.wxLoginCodeZkt();
        this.wxLoginCodeP();
        this.onPageSizeChange();
    },
});
</script>
<style lang="less" scoped>
@import './index';
</style>
