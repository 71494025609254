/*
 * @Description:
 * @Author: luqisheng
 * @Date: 2024-03-25 15:01:32
 * @LastEditTime: 2024-03-26 18:32:12
 * @LastEditors: luqisheng
 */
import request from '@/utils/request/index';
import { PREFIX } from '@/api/index';
// 设置密码
export function changePasswordGet(data) {
    return request({
        url: PREFIX.XYB + '/user/auth/change/password',
        method: 'post',
        data,
    });
}
// 账号密码状态
export function userAccountPwdGet(data) {
    return request({
        url: PREFIX.XYB + '/user/account/pwd/status',
        method: 'post',
        data,
    });
}
// 手机区号列表
export function phoneCodeLictGet(params) {
    return request({
        url: PREFIX.XYB + '/phone/code/list',
        method: 'get',
        params,
    });
}
// i18n语言列表
export function getLanguageList(params) {
    return request({
        url: PREFIX.XYB + '/language/list',
        method: 'get',
        params,
    });
}
// 登录
export function postUserAccountLogin(data) {
    return request({
        url: `${PREFIX.XYB}/user/auth/login/account`,
        method: 'post',
        data,
    });
}
// 国家列表
export function getCountryList(params) {
    return request({
        url: `${PREFIX.XYB}/country/list`,
        method: 'get',
        params,
    });
}

// 微信扫码登录
export function postUserWxLogin(data) {
    return request({
        url: `${PREFIX.XYB}/user/auth/login/v2`,
        method: 'post',
        data,
    });
}
