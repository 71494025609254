<!--
 * @Description: 
 * @Author: luqisheng
 * @Date: 2024-03-25 13:59:44
 * @LastEditTime: 2024-03-25 15:18:48
 * @LastEditors: luqisheng
-->
<template>
    <router-view />
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
    mounted() {
        document.dispatchEvent(new Event('custom-render-trigger'));
        // document.dispatchEvent(new Event('render-event'));
    },
});
</script>

<style lang="less">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}
</style>
