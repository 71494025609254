export default {
    computed: {},
    data() {
        return {
            isXyeEnv: process.env.VUE_APP_NAMECODE === 'XYE',
            isXyePlusEnv: process.env.VUE_APP_NAMECODE === 'XYE_PLUS',
        };
    },
    computed: {
        companyName() {
            let companyName = '';
            if (this.isXyeEnv) companyName = '广州遇乐网络有限公司'
            if (this.isXyePlusEnv) companyName = '广东小娱儿网络有限公司'
            return companyName;
        },
        footerIcpText() {
            let icpText = '';
            if (this.isXyeEnv) icpText = 'Copyright 2022 xye.wang 粤ICP备17060322号-20'
            if (this.isXyePlusEnv) icpText = 'Copyright 2024 xye.cn 粤ICP备17060322号-20'
            return icpText;
        },
    },
    methods: {},
};
