/*
 * @Description:
 * @Author: luqisheng
 * @Date: 2024-03-25 17:24:41
 * @LastEditTime: 2024-03-28 13:53:14
 * @LastEditors: luqisheng
 */
console.log('process.env.NODE_ENV ', process.env.NODE_ENV);
export const baseUrl = {
    default: `https://${process.env.VUE_APP_API_BASEPATH}/zk-api-b`,
    zk: `https://${process.env.VUE_APP_API_BASEPATH}/zk-api-b`,
    xyb: `https://${process.env.VUE_APP_API_BASEPATH}/xy-api-b/b`,
};

// 验签相关配置
export const SercetConfig = {
    PublicKey: `-----BEGIN PUBLIC KEY-----
				  MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC6DEvhZtAn+ATKU5wVOHCD6/Q5
				  G+C/q+lTmEjONu2+Fqg7FoU5pX4H1DfNMXf5o0VpsZbqasQZzf2nB6uZ2ozuxPdZ
				  Ss6ZVb3nsfu7ynn2I/RKoaiZ09oS0AWAM33nsVxF9wTIqJLILdjUJ+a6BDtsUNzb
				  W7XMrstmlX8uEBJPkwIDAQAB
			-----END PUBLIC KEY-----`,
    FingerPrint: '3c318162599b5dc81f86d4465f2bf764',
    AccessSecret: '57B2448D7AF3D89819F6A63109883BB9',
};

const tailMap = {
    dev: 'xye-zkt-dev',
    test: 'xye-zkt-test',
    'test-xjb': 'xye-zkt-test-xjb',
    pre: 'xye-zkt-pre',
    pro: 'xye-zkt-pro',
};
let tail = tailMap[process.env.VUE_APP_NODE_ENV] || tailMap['dev'];

const redirectUri = encodeURIComponent(`${process.env.VUE_APP_LOGIN_REDIRECT_URI}/rx?x=${location.href}`);

export const LoginConfig = {
    LOGIN_WAY: `xy_unify`,
    LOGIN_APPID: 'wx612d4e5089f51ae8',
    LOGIN_REDIRECT_URI: redirectUri,
};

export const XyePlusLoginConfig = {
    LOGIN_WAY: `xy_unify`,
    LOGIN_APPID: 'wx6e98bc35797731c0',
    LOGIN_REDIRECT_URI: redirectUri,
};

// 语言资源
export const localeList = [
    {
        key: 'zh-CN',
        value: 'zh-Hans',
        label: '中文简体',
        // icon: "https://img.yee.ba/xye-c/i18n/CN%402x.png",
    },
    {
        key: 'en',
        value: 'en',
        label: 'English',
        // icon: "https://img.yee.ba/xye-c/i18n/EN%402x.png",
    },
    {
        key: 'th',
        value: 'th',
        label: 'ไทย',
        // icon: "https://img.yee.ba/xye-c/i18n/EN%402x.png",
    },
    {
        key: 'zh-TW',
        value: 'zh-Hant',
        label: '繁体中文',
        // icon: "https://img.yee.ba/xye-c/i18n/CN%402x.png",
    },
];
